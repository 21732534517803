<template>
    <span :id="id">
        {{ num }}
    </span>
</template>

<script>
import { v4 } from 'uuid';

export default {
    name: 'NumberCounter',
    props: {
        num: Number
    },
    data() {
        return {
            id: v4()
        };
    },
    updated() {
        this.animateValue(0, this.num, 2000);
    },
    methods: {
        animateValue(start, end, duration) {
            let startTimestamp = null;
            const step = timestamp => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min(
                    (timestamp - startTimestamp) / duration,
                    1
                );
                document.getElementById(this.id).innerHTML = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }
    }
};
</script>

<style></style>

<template>
    <section class="tile is-ancestor">
        <div class="tile is-vertical">
            <div class="tile">
                <div class="tile is-parent is-8">
                    <article class="tile is-child box is-relative">
                        <b-loading
                            :is-full-page="false"
                            v-model="selfInfoLoading"
                        />
                        <div class="content">
                            <div class="columns is-vcentered">
                                <div
                                    class="
                                        column
                                        is-one-third
                                        is-flex
                                        is-justify-content-center
                                        is-hidden-touch
                                    "
                                >
                                    <img
                                        v-if="!fotoEmpresa"
                                        :src="require('@/assets/checklist.png')"
                                        width="200"
                                        alt="home-main-png"
                                    />
                                    <img
                                        v-else
                                        :src="fotoEmpresa"
                                        class="t-image"
                                        alt="home-main-png"
                                    />
                                </div>
                                <div class="column">
                                    <h2>
                                        Bienvenido(a)
                                        <span
                                            class="has-text-primary-dark"
                                            style="font-size: 1.4rem"
                                        >
                                            {{
                                                this.currentPerfil.trabajador
                                                    .nombre
                                            }}
                                        </span>
                                    </h2>
                                    <br />
                                    <div
                                        class="
                                            columns
                                            is-multiline is-vcentered
                                        "
                                    >
                                        <div
                                            v-for="link of directAccess"
                                            :key="link.id"
                                            class="column is-one-thrid"
                                        >
                                            <b-button
                                                tag="router-link"
                                                type="is-primary is-light"
                                                expanded
                                                :to="link.path"
                                            >
                                                {{ link.name }}
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child box is-relative">
                        <b-loading
                            :is-full-page="false"
                            v-model="isLoading.stats"
                        ></b-loading>
                        <div class="content">
                            <h4 class="has-text-primary-dark">Estadísticas</h4>
                            <div
                                class="
                                    columns
                                    is-flex-wrap-wrap
                                    is-justify-content-space-evenly
                                    is-align-items-center
                                "
                            >
                                <div class="column stats-item is-half level">
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <h6 class="heading">
                                                Doc. firmados
                                            </h6>
                                            <p class="title has-text-link">
                                                <number-counter
                                                    style="font-size: 1.75rem"
                                                    :num="
                                                        stats.documentos_firmados
                                                    "
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="column stats-item is-half level">
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <h6 class="heading">
                                                Doc. sin firmar
                                            </h6>
                                            <p class="title has-text-link">
                                                <number-counter
                                                    style="font-size: 1.75rem"
                                                    :num="
                                                        stats.documentos_a_firmar
                                                    "
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="column stats-item is-half level">
                                    <div class="level-item has-text-centered">
                                        <div>
                                            <h6 class="heading">
                                                Usuarios Activos
                                            </h6>
                                            <p class="title has-text-link">
                                                <number-counter
                                                    style="font-size: 1.75rem"
                                                    :num="
                                                        stats.cantidad_usuarios_activos
                                                    "
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div class="tile is-parent">
                <article class="tile is-child box is-relative">
                    <b-loading
                        :is-full-page="false"
                        v-model="isLoading.historial"
                    />
                    <line-chart label="Documentos Firmados" />
                </article>
            </div>
        </div>
    </section>
</template>

<script>
import LineChart from '../../components/Home/LineChart.vue';
import NumberCounter from '../../components/shared/NumberCounter.vue';

import { BASE_URL } from '@/config';
import Message from '@/utils/Message';

export default {
    components: {
        NumberCounter,
        LineChart,
    },
    data() {
        return {
            isLoading: {
                stats: false,
                historial: false,
            },
            base_url: BASE_URL,
            directAccess: [
                {
                    id: 1,
                    path: '/a/documentos/publicacion/masiva',
                    name: 'Publicacion Masiva',
                },
                {
                    id: 2,
                    path: '/a/documentos/buscador',
                    name: 'Buscador de documentos',
                },
                {
                    id: 3,
                    path: '/a/trabajadores/carga/masiva',
                    name: 'Agregar Empleados',
                },
                {
                    id: 4,
                    path: '/a/reportes',
                    name: 'Reportes',
                },
                {
                    id: 5,
                    path: '/a/documentos/tipos-documentos',
                    name: 'Tipo de Documentos',
                },
            ],
        };
    },
    mounted() {
        this.fetchStats();
        this.fetchHistorial();
    },
    computed: {
        currentPerfil() {
            return this.$store.state.me.perfil;
        },
        stats() {
            return this.$store.state.empresas.stats;
        },
        fotoEmpresa() {
            return this.$store.state.me.perfil.trabajador.empresa.ruta_imagen
                ? `${this.base_url}/${this.$store.state.me.perfil.trabajador.empresa.ruta_imagen}`
                : null;
        },
        selfInfoLoading() {
            return this.$store.state.me.isLoading;
        },
    },
    methods: {
        async fetchStats() {
            this.isLoading.stats = true;
            try {
                const res = await this.$store.dispatch('empresas/getStats');
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.isLoading.stats = false;
            }
        },
        async fetchHistorial(cantidadDias = 7) {
            this.isLoading.historial = true;
            try {
                const res = await this.$store.dispatch(
                    'documentos/getStats',
                    cantidadDias
                );
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.isLoading.historial = false;
            }
        },
    },
};
</script>

<style>
.stats-item {
    transition: all 0.3s ease;
}

.stats-item:hover {
    transform: scale(1.05);
}
</style>
